import "core-js/modules/es.array.push.js";
import Headers from "@/components/headers";
import Tabbar from "@/components/tabbar";
import { getHongLi } from "@/api";
export default {
  data() {
    return {
      barIndex: 0,
      time: 5 * 60 * 60 * 1000,
      pList: [10000, 30000, 60000, 150000],
      percentages: []
    };
  },
  mounted() {
    if (!this.userInfo.uid) {
      this.$router.replace("/login");
      return;
    } else {
      let bfb;
      this.pList.forEach(res => {
        bfb = Math.floor(this.userInfo.contribution / res * 100);
        if (bfb > 100) bfb = 100;
        this.percentages.push(bfb);
      });
    }
  },
  methods: {
    goLingqu(value) {
      if (value) {
        this.lingqu();
      } else {
        this.$router.push("/projects");
      }
    },
    lingqu() {
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      getHongLi().then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        this.$store.dispatch("getUserInfo");
      });
    },
    selectBar(val) {
      this.barIndex = val;
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  watch: {
    userInfo(value) {
      this.pList.map(res => {
        let bfb = parseFloat(value.contribution / res).toFixed(2) * 100;
        if (bfb > 100) bfb = 100;
        this.percentages.push(bfb);
      });
    }
  },
  components: {
    Tabbar,
    Headers
  }
};